.input-wrap{
  padding: 10px;
  max-width: 480px;
}
.input-survey{
    display: block;
    padding: 0px;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 30px;
    border-width: 1px;
    border-radius: 3px;
    text-align: left;

    width: 100%;
    margin-top: 10px;
  }

.input-survey-email{
    display: block;
    padding: 0px;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 30px;
    border-width: 1px;
    border-radius: 3px;
    text-align: left;
    padding: 10px;
    width: 460px;
    margin-top: 10px;
  }
.input-survey:hover, .input-survey:focus, .input-survey:active{
  border-width: 1px;
  box-shadow: 0px;
  outline: none;
}
.input-survey::placeholder{
  color:#ddd;
  text-align:left;
}