html{
  height: 100%;
}
body {
  display: flex;
  height: 100%;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root{
  display:flex;
  flex:1;
}

.noflex{
  display: inline;
}
.t-align-c{
  text-align:center;
}

.main-test{
  display: flex;
  flex: 1;
	align-items: center;
	justify-content: center;
  flex-direction: column;
}

.justify-content-center{
  justify-content: center;
}

.main{
  display: block;
  flex: 1;
  width: 100vw;
  flex-direction: column;
}

.flex{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.row{
  flex-direction:row;
}

.ex {
  -webkit-transition:0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden; 
  margin-top:500px;
}
.ex.show {
  opacity: 1;
  visibility: visible; 
  margin-top:0px;

}

.ex.hide {
  opacity: 0;
  visibility: hidden; 
  margin-top:-500px;

}

@media screen and (max-width: 548px) {
  .box{
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    align-self: center;
    justify-content: center;
    align-content: center;
    align-items: center;
  } 
  .input{
    display: block;
    padding: 0px;
    font-size: 26px;
    line-height: 26px;
    margin-bottom: 0px;
    border-width: 0px;
    text-align: center;
    margin-top: 20px;
  }
  
  .time-s{
    font-size: 26px;
    margin-left: 0px;
  }
  .text-para-justify{
    margin-left: 10%;
    margin-right: 10%;
    text-align: justify;
    text-justify: auto;
    font-size: 16px;
    color:#555;
    margin-bottom: 10px;
  }
  
  .para-label{
    font-size: 26px;
    line-height:40px;
    color:#000;
    font-weight: 500;
    margin-bottom: 0px;
    text-align: center;
    padding-top: 20px;
  }
  
  .width-100-max-800{
      width: 100%;
      height: 100%;
      text-align: center;
      align-self: center;
      max-width: 800px;
      min-height: 250px;

    }
  .width-max{
    width: 100%;
    max-width: 800px;
    align-self: center;
  }
  .input-100{
      min-width: 100%;
    }
  .mobile-min-h-350{
    min-height: 350px;
  }
  .mobile-min-h-300{
    min-height: 300px;
  }
   .header-link{
      font-size: 3.1vw;
      font-weight: 700;
      align-self: center;
      margin-left: 0px;
      margin-right: 0px;
      color:#999;
      padding-left:15px;
      padding-right:15px;
      padding-top:6px;
      padding-bottom:6px;
    } 
    .header-link.active, .header-link:hover{
      color:#ff0000;
      cursor: pointer;
      background-color: #f2f3f7;
      border-radius: 4px;
    }
    .header-link-wrapper{
      display: flex;
      justify-content: flex-start;
      padding-left:0px;
      height: 50px;
      min-width: 300px;
    }
}
@media screen and (min-width: 549px) {
    .box {
      display: flex;
      flex-direction: column;
      margin-left:5%;
      margin-right:5%;
      text-align: center;
      align-self: center;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
    .input{
      font-size: 35px;
      margin-bottom: 0px;
      border-width: 0px;
      text-align: center;
      margin-top: 26px;
      
    }
    .time-s{
      font-size: 35px;
    }
    .width-70{
      margin-left:15%;
      margin-right:15%;
    }
    .width-50{
      width: 50%;
      margin: 0 auto;
    }
    .width-40{
      width: 40%;
      margin: 0 auto;
    }
    .width-25{
      width: 25%;
      margin: 0 auto;
    }
    .text-para{
      max-width: 800px;
      margin: 0 auto;
      text-align: justify;
      text-justify: auto;
      margin-bottom: 10px;
    }
    .left-800{
      max-width: 800px;
      margin: 0 auto;
      text-align: justify;
      text-justify: auto;
      margin-bottom: 10px;
    }
    .para-label{
      font-size: 35px;
      line-height:40px;
      color:#000;
      font-weight: 500;
      margin-bottom: 0px;
      text-align: center;
      padding-top: 40px;
    }
    .choice-button{
      margin-left: 5px;
      margin-right: 5px;
    }

    .width-100-max-800{
      width: 100%;
      height: 100%;
      text-align: center;
      align-self: center;
      max-width: 800px;
      min-height: 400px;
    }
    .width-max{
      width: 100%;
      max-width: 800px;
      align-self: center;
      text-align: left;
    }
    .input-100{
      width: 100vw;
    }
    .header-link{
      font-size: 17px;
      font-weight: 700;
      line-height: 23px;
      align-self: center;
      margin-left: 15px;
      margin-right: 15px;
      color:#999;

    }
    .header-link.active, .header-link:hover{
      color:#000;
      cursor: pointer;
      border-radius: 4px;
    }
    .header-link-wrapper{
      display: flex;
      justify-content: flex-start;
      padding-left:10px;
      height: 50px;
      min-width: 300px;
      position: absolute;
      right:0px;
    }
}

.title-left{
  text-align: left;
  margin-bottom: 10px;
  font-size: 29px;
  padding-top: 10px;
}
.text-para-left{
    text-align: left;
    font-size: 16px;
    color:#555;
    margin-bottom: 10px;
    width: auto;
  }
.label {
  font-size: 26px;
  line-height:40px;
  color:#000;
  font-weight: 500;
  margin-bottom: 0px;
}

.label2 {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
  color:#555;
  margin-bottom: 0px;
  text-align: center;
}

.label3 {
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
  color:#333;
  text-align: center;
}
.label3-holder{
  padding-left: 20px;
  padding-right: 20px;
}

::placeholder{
  color:#ddd;
  text-align:center;
}
.input:hover, .input:focus, .input:active{
  border-width: 0px;
  box-shadow: 0px;
  outline: none;
}
.button-wrap{
  height: 50px;
  margin-top: 30px;
}
.button{
  font-size:1em;
  color:#fff;
  background-color:#ff0000;
  border-width:0px;
  border-radius: 3px;
  letter-spacing: 3px;
  font-weight: 700;
  padding: 10px 20px;
  transition: 0.5s;
  text-align:center;

}

@media (pointer:fine) {
  .button:hover{
    background-color:#000;
  }
}
 .button:focus{
    outline:none;
  }

.focused{
  background-color: #000;
}

.button-line{
  margin:5px;
}
.or_text{
  padding: 10px;
}
.margin20{
  margin-top: 20px;
  margin-bottom: 20px;
}
.margin40{
  margin-top: 40px;
  margin-bottom: 40px;
}
.height60{
  min-height:60px;
  margin-bottom:24px;
}
.skip{
  letter-spacing: 3px;
}
.input-wrapper{
  margin-right:0px;
}

.uni-input-wrapper{
 
  text-align: center;
}
.footer{
  flex-direction: row;
  position: absolute;
  bottom: 0;
  left:0;
  background-color:rgba(200,200,200,0.1);
  padding:5px;
  padding-left:10px;
  width:95vw;
  justify-content: space-between;
  align-items: center;
}

.footer-buttons{
  flex-direction: row;
}

/*turn off input spinners*/
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.bar-wrapper{
  height:5px;
  width: 200px;
  background-color:#ddd;

  border-radius: 5px;

}
.bar{
  height:5px;
  width:200px;
  background-color:red;
  border-radius: 5px;
}

.width-100{
  display: flex;
  width: 100vw;
  text-align: center;
  align-content: center;
  justify-content: center;
}
.width-100p{
  width: 100%;
}
.inner-box{
  width: auto;
  max-width: 800px;
  padding-left: 20px;
  padding-right: 20px;
}
.gray{
  background-color:#ddd;
}
.box-red{
  background-color:#ff0000;
  min-height:100vh;
}
.logo-wrap.no-shadow{
  box-shadow: 0 0 0 0;
}

.padding-5per{
  padding:5%;
}
.white{
  color: #fff;
}
.logo-wrap{
  padding: 5px;
  padding-left:10px;
  padding-top:10px;
  box-shadow: 0 0 13px 0 rgba(82,63,105,.1);
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}
.logo{
  margin: 0 auto;
}
.header-fixed{
  width: 100vw;
  position: absolute;
  top:0;
  left:0;
}
.header-relative{
  width: 100vw;
}




.completed{
  font-size:10px;
}
.completed-box{
  flex-direction:column;
  padding-bottom:5px;
}
.up-down-button{
  padding:0px;
  width:36px;
  height:30px;
  font-size:1em;
  color:#fff;
  background-color:#ff0000;
  border-width:0px;
  border-radius: 3px;
  letter-spacing: 3px;
  font-weight: 700;
  transition: 0.5s;
  margin:1px;
  font-size:14px;
  align-items: center;
  justify-content: center;
  text-align:center;

}
.up-down-button:focus{
  outline:none;
}
button:disabled, button[disabled]{
  background-color:#ddd;
}
.border-white{
  border: 1px solid white;
}
button.border-white:hover{
  border: 1px solid transparent;
}
span{
  margin-left: auto;
  margin-right: auto;
}
.margin-tb-20{
  margin-top: 20px;
  margin-bottom: 20px;
}
.margin-t-20{
  margin-top: 20px;
  margin-right: 0px;
}
.margin-t-10{
  margin-top: 10px;
}
.margin-t-30{
  margin-top: 30px;
}
.margin-b-0{
  margin-bottom: 0px;
}
.checkbox{
  margin: 0px 10px;
  font-size: 26px;
}
.checkbox-text{
  font-size: 16px;
  line-height: 28px;
}
.button-grade{
  font-size:1em;
  color:#fff;
  background-color:#ff0000;
  border-width:0px;
  border-radius: 3px;
  letter-spacing: 3px;
  font-weight: 700;
  padding: 10px 0px;
  transition: 0.5s;
  text-align:center;
  margin: 2px 2px;
}
.button-grade:hover{
  background-color:#000;
}
.button-grade:focus{
  outline:none;
}
.button-grade.focused{

  background-color:#000;

}
.t-align-l{
  text-align: left;
}
.bold{
  font-weight:700;
  letter-spacing:2px;
  color:#666;
}
.margin-w-10{
  margin-left: 10px;
  margin-right: 10px;
}
.title{
  font-size: 29px;
  padding-top: 10px;
  text-align:center;
}
.red-bgnd{
  background-color: #ff0000;
  padding-top: 60px;
}
.white-bgnd{
  background-color: white;;
}
.padding-b-20{
  padding-bottom:20px;
}
.padding-t-20{
  padding-top: 40px;
}
.padding-b-40{
  padding-bottom:40px;
}
ul{
    display: inline-block;
    list-style-type: disc;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
    text-align: left;
  }
li{
  margin-bottom:10px;
}
.lowercase{
  text-transform: lowercase;
}
.no-text-justiy{
  text-align: left;
  text-justify: none;
}
strong{
  font-weight: 700;
  font-size:18px;
}
.percent-wrapper{
  text-align: center;
  width: 150px;
  padding: 5px;
  padding-bottom: 10px;
  padding-top:10px;
}
.percent{
  font-size: 30px;
  color:#333;
}
.percent-label{
  font-size: 14px;
}
.fb-button{
  display: flex;

  background-color:#415a94;
  border-width:0px;
  border-radius: 4px;
  letter-spacing: 4px;
  font-weight: 700;
  padding: 0px 20px 0px 10px;
  transition: 0.5s;
  align-items: center;
  margin-top: 10px;
}
.fb-button-text{
  color: white;
  font-size:12px;
  line-height: 18px;
  margin-top:2px;
}
.react-share__ShareButton:focus{
  outline:none;
}
.table{
  display: flex;
  flex-direction: column;
  justify-content:center;
}
.no-top-margin{
  margin-top:0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.t-align-r{
  text-align: right;
}
.sec-width{
  width: 100px;
}
.dashboard-gray{
  background-color: #ececef;
}
.dashboard-card{
  background-color: #f2f2f2;
  padding: 5px;
  box-shadow: 0 0 13px 0 rgba(82,63,105,.1);
  border-radius: 4px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.dashboard-card-small{
  background-color: #f2f2f2;
  padding: 20px;
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: 0 0 13px 0 rgba(82,63,105,.1);
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.checkbox-wrapper {
  display: flex;
  flex:0;
  justify-content: center;
  align-content: center;

}